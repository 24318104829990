import React, { useContext } from "react"
import { graphql } from "gatsby"
import BaseLayout, { getNavLinks } from "../components/base"
import {
  BodyCopy,
  Box,
  Brevier,
  PageLink,
  DoublePica,
  SiteName,
} from "../utils/styledComponents"
import SEO from "../components/seo"
// import peach_bubble from "../../static/peach_bubble.svg"
// import speech_bubble from "../../static/speech_bubble.svg"
import styled from "@emotion/styled"
import theme from "../utils/theme"
import { ThemeProvider } from "emotion-theming"
import NavContext from "../utils/navContext"

const LinkSection = props => {
  return (
    <React.Fragment>
      <Brevier color="neonGreen" cursor="pointer">
        {props.title}
      </Brevier>
      {props.links.map((item, index) => (
        <PageLink
          key={`${props.title || ""}_link_${index}`}
          to={`/${item.slug}`}
        >
          <DoublePica color="white" cursor="pointer">
            {item.seoName}
          </DoublePica>
        </PageLink>
      ))}
    </React.Fragment>
  )
}

const Hero = styled(Box)({
  width: "auto",
  backgroundAttachment: "fixed",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  display: "flex",
  justifyContent: "space-between",
  minHeight: "500px",
  // [theme.mediaQueries[1]]: {
  //   minHeight: theme.sizes[5],
  // },
})

// const SpeechBubble = props => {
//   return (
//     <Box
//       background={props.background}
//       backgroundAttachment="fixed"
//       backgroundRepeat="no-repeat"
//       backgroundSize="contain"
//       {...props}
//     >
//       {props.children}
//     </Box>
//   )
// }

const HomePage = props => {
  const { data } = props
  const { allSanityPage, allSanityCity, sanitySiteSettings } = data

  const navConsumer = useContext(NavContext)
  const navLinks = getNavLinks(
    allSanityCity.nodes.map(city => city.slugMeta) || [],
    allSanityPage.edges.map(page => page.node.slugMeta) || []
  )

  React.useEffect(() => {
    navConsumer.setCurrentPage("Menu")
    navConsumer.setNavLinks(navLinks)
    // eslint-disable-next-line
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <BaseLayout
        location={props.location}
        backgroundColor="black"
        headerTextColor="lightOrange"
        footerTextColor="lightOrange"
      >
        <SEO title="Home" />
        <Hero
          background={`url(${sanitySiteSettings.images[0].image.asset.url})`}
        >
          {/* <SpeechBubble background={`url(${speech_bubble})`} width="70vh">
            <Paragon
              css={{ transform: "translate(15%, 50%)" }}
              color="blue"
              fontSize={[5, 6]}
              width="80%"
            >
              Where are we going tn???
            </Paragon>
          </SpeechBubble>
          <SpeechBubble
            background={`url(${peach_bubble})`}
            width="40vh"
            display="flex"
            justifyContent="flex-end"
            backgroundPosition="bottom"
          >
            <Paragon
              color="blue"
              fontSize={[5, 6]}
              width="80%"
              css={{ transform: "translate(10%, 60%)" }}
            >
              idk!?!...
            </Paragon>
          </SpeechBubble> */}
        </Hero>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          width="100%"
        >
          <Box
            p={[4, 5]}
            display="flex"
            width="100%"
            justifyContent="flex-start"
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="space-between"
              backgroundColor="white"
              p={[4, 5]}
              maxWidth="600px"
              mt="-150px"
            >
              <Brevier>Made with Love,</Brevier>
              <SiteName color="darkGray" pb={[3, 4]}>
                Nibbles
                <br />
                .Guide
              </SiteName>
              <BodyCopy lineHeight={["24px", "28px"]}>
                {sanitySiteSettings.description}
              </BodyCopy>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
            justifyContent="space-between"
            py={[3, 4]}
            width="100%"
          >
            <Box
              width="100%"
              display="flex"
              alignItems="flex-start"
              justifyContent="flex-start"
              flexWrap="wrap"
              maxWidth="600px"
              px={[4, 5]}
            >
              <Box pr={[4, 6]}>
                {allSanityCity.nodes && (
                  <LinkSection
                    title="Cities"
                    links={allSanityCity.nodes.map(city => city.slugMeta)}
                  />
                )}
              </Box>
              <Box>
                {allSanityPage.edges && (
                  <LinkSection
                    title="etc."
                    links={allSanityPage.edges.map(page => page.node.slugMeta)}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </BaseLayout>
    </ThemeProvider>
  )
}

export const pageQuery = graphql`
  query indexPageQuery {
    allSanityPage(
      filter: { slugMeta: { hidden: { ne: true }, seoName: {}, slug: {} } }
    ) {
      edges {
        node {
          slugMeta {
            slug
            seoName
          }
        }
      }
    }
    allSanityCity(
      sort: { order: ASC, fields: name }
      filter: { slugMeta: { hidden: { ne: true } } }
    ) {
      nodes {
        name
        slugMeta {
          slug
          seoName
        }
      }
    }
    sanitySiteSettings(_id: { eq: "siteSettings" }) {
      title
      description
      images {
        image {
          asset {
            url
          }
        }
      }
    }
  }
`

export default HomePage
